<template>
  <div class="app-container">

    <div class="filter-container">
      <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
      <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="设备" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device_address_and_name }}
        </template>
      </el-table-column>
      <el-table-column label="大箱使用次数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.l_count }}
        </template>
      </el-table-column>
      <el-table-column label="小箱使用次数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.s_count }}
        </template>
      </el-table-column>
      <el-table-column label="总使用次数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.all_count }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        start_time: "",
        end_time: "",
      },
    };
  },
  created() {
    this.listQuery.school_id = this.$route.query.school_id
    this.listQuery.start_time = this.$route.query.start_time
    this.listQuery.end_time = this.$route.query.end_time
    this.getList();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/deviceOrder/deviceCount",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
